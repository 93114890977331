import cards from './images/payment_icons.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useRef, useEffect } from 'react';

export default function Card({ domlec, card, updateCard, progress, updateProgress, makePaymentRef }) {

    const emailRef = useRef(null);
    const nameRef = useRef(null);
    const cardNumberRef = useRef(null);
    const expiryRef = useRef(null);
    const cvvRef = useRef(null);
    const amountRef = useRef(null);


    function validateData() {
        //if all the fields have valid data, then and only then enable the make payment button and update the progress marker
        //console.log(validateEmail(), validateName(), validateCard(), validateExpiryDate(), validateCvv(), validateAmount()) 
        if (validateName() && validateCard() && validateExpiryDate() && validateCvv() && validateAmount()) {
            //makePaymentRef.current.disabled = false;
            updateProgress({ ...progress, step3: true })
        }
        else {
            //makePaymentRef.current.disabled = true;
            updateProgress({ ...progress, step3: false })
        }
    }

    function validateAmount() {
        //console.log(parseFloat(amountRef.current.value))
        //return (parseFloat(amountRef.current.value) >= domlec['minvend'] &&
        //    parseFloat(amountRef.current.value) <= domlec['maxvend'])
        return (parseFloat(card.amount) >= domlec['minvend'] &&
            parseFloat(card.amount) <= domlec['maxvend'])
    }

    function validateCvv() {
        //CVV is either 3 or 4 numbers
        //return (cvvRef.current.value.length >= 3 && cvvRef.current.value.length <= 4)
        return (card['cvv'] && card['cvv'].length >= 3 && card['cvv'].length <= 4)
    }

    function checkPrevMonthsThisYear() {
        // for the current YY, ensure previous months are not used

        let result = true;

        const currentYear = new Date().getFullYear() - 2000;
        if (card['expiry'].length === 4) {
            // only check when the full expiry date has been entered

            let expYear = parseInt(card['expiry'].substring(2, 4), 10); //get the YY bit

            if (expYear === currentYear) {
                //only need to do this if they expiry date is within the current year
                try {
                    let currentMonth = new Date().getMonth() + 1; //1 = Jan, 12 = Dec
                    // currentMonth = 2; //debug
                    let expMonth = parseInt(card['expiry'].substring(0, 2), 10); //get the MM bit
                    // console.log(`current month: ${currentMonth} expMonth: ${expMonth} - ${currentMonth <= expMonth}`);
                    return (currentMonth <= expMonth); //expMonth is this month or later but not before
                } catch (error) {
                    console.error(error);
                }
            }
        }

        return result;
    }

    function yearsFromThisYear() {
        const currentYear = new Date().getFullYear() - 2000;

        let regex = `${currentYear}`;
        for (let yr = currentYear + 1; yr <= currentYear + 10; yr++) {
            regex += `|${yr}`;
        }
        return regex;
    }

    function validateExpiryDate() {
        //expiry date has to be in the format MMYY
        //valid years will be 10 years from the current year

        let tenYears = yearsFromThisYear(); //eg "25|26|27|28|29|30|31|32|33|34|35"
        const regex = new RegExp(`^(0[1-9]|1[0-2])(${tenYears})$`);
        // console.log(`regex: ${regex}`);

        return (checkPrevMonthsThisYear() && regex.test(card['expiry']));
    }

    function validateCard() {
        //card must be at least 16 numbers
        //return (cardNumberRef.current.value.length === 16)
        return (card['number'] && card['number'].length === 16)
    }

    function validateName() {
        //must have a first name or initial, a space and at least another letter
        const regex = /^[a-zA-Z]{1,}\s[a-zA-Z]{1,}.*$/;
        //return regex.test(nameRef.current.value);
        return regex.test(card['name']);
    }

    function validateEmail() {
        //must have an @ and a .
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        //return regex.test(emailRef.current.value)
        return regex.test(card['email'])
    }

    function handleInputChange(event) {
        //here we run various checks to ensure data entered is valid
        //console.log(`event name: ${event.target.name} value: ${event.target.value} amt ref: ${amountRef.current.value}`)
        switch (event.target.name) {
            case 'email': {
                updateCard({ ...card, email: event.target.value })
                break;
            }
            case 'cardHolder': {
                updateCard({ ...card, name: event.target.value })
                break;
            }
            case 'cardNumber': {
                const tempCard = event.target.value;
                //4 = visa and 5 = mastercard
                if (tempCard.charAt(0) === '4' || tempCard.charAt(0) === '5' || tempCard.charAt(0) === '2') {
                    updateCard({ ...card, number: event.target.value.replace(/[^0-9]/g, "") })
                }
                else {
                    updateCard({ ...card, number: event.target.value.replace(/[^4-5]/g, "") })
                }
                break;
            }
            case 'amount': {
                updateCard({ ...card, amount: event.target.value })
                break;
            }
            case 'cvv': {
                updateCard({ ...card, cvv: event.target.value.replace(/[^0-9]/g, "") })
                break;
            }
            case 'expiry': {
                let tempExpiry = event.target.value;
                //lastpass autofills with MM/YYYY so we need to rewrite as MMYY
                if (tempExpiry !== undefined && tempExpiry.length === 7) {
                    tempExpiry = tempExpiry.slice(0, 2) + tempExpiry.slice(5)
                }

                updateCard({ ...card, expiry: tempExpiry })
                break;
            }
            default: {
                console.log(`default case ${event.target.name}`)
            }
        }

    }

    useEffect(() => {
        validateData(); //test for validation (and enable/disable the button whenver the input changes)

    }, [card]);


    const vendLimits = `(from $${domlec['minvend']} to $${domlec['maxvend']})`
    return (
        <>
            <div className='row'>
                <div className='twelve columns'>
                    <h4>CARD DETAILS</h4>
                </div>
            </div>
            <div className='row'>
                <div className='six columns'>
                    <label htmlFor='email'>Your Email Address (for the receipt) *optional*
                        <span className='star'></span>
                        {
                            validateEmail() ?
                                <span className='greenTick'>
                                    <FontAwesomeIcon color='var(--green-color)' icon={faCheck} />
                                </span>
                                :
                                <>
                                </>
                        }
                    </label>
                    <input
                        ref={emailRef}
                        type="email"
                        name="email"
                        value={card['email']}
                        onInput={handleInputChange}
                        className='u-full-width'
                        placeholder='something@gmail.com'
                        autoFocus
                        required
                    >

                    </input>
                </div>
                <div className='six columns'>
                    <label htmlFor='cardHolder'>Card Holder Name<span className='star'></span>
                        {
                            validateName() ?
                                <span className='greenTick'>
                                    <FontAwesomeIcon color='var(--green-color)' icon={faCheck} />
                                </span>
                                :
                                <>
                                </>
                        }
                    </label>
                    <input
                        ref={nameRef}
                        type="text"
                        name="cardHolder"
                        value={card['name']}
                        onInput={handleInputChange}
                        className='u-full-width'
                        placeholder='John Doe'
                        required
                    >
                    </input>
                </div>
            </div>
            <div className='row'>
                <div className='six columns'>
                    <label htmlFor='cardNumber'>Card Number
                        <span className='star'></span>
                        <span>
                            <img
                                className='cards-span'
                                src={cards}
                                alt='Visa and Mastercard logo' />
                        </span>
                        {
                            validateCard() ?
                                <span className='greenTick'>
                                    <FontAwesomeIcon color='var(--green-color)' icon={faCheck} />
                                </span>
                                :
                                <>
                                </>
                        }
                    </label>
                    <input
                        ref={cardNumberRef}
                        type="text" maxLength={16}
                        name="cardNumber"
                        value={card['number']}
                        onInput={handleInputChange}
                        className='u-full-width'
                        placeholder='1234 5678 9999 4321'
                        required
                    >

                    </input>
                </div>
            </div>
            <div className='row'>
                <div className='three columns'>
                    <label htmlFor='expiry'>Expiry Date<span className='star'></span>
                        {
                            validateExpiryDate() ?
                                <span className='greenTick'>
                                    <FontAwesomeIcon color='var(--green-color)' icon={faCheck} />
                                </span>
                                :
                                <>
                                </>
                        }
                    </label>
                    <input
                        ref={expiryRef}
                        type="text"
                        minLength={3}
                        maxLength={4}
                        autoComplete='cc-exp'
                        name='expiry'
                        value={card['expiry']}
                        onInput={handleInputChange}
                        placeholder='MMYY'
                        required
                    >
                    </input>
                </div>
            </div>
            <div className='row'>

                <div className='three columns'>
                    <label htmlFor='cvv'>CVV2<span className='star'></span>
                        {
                            validateCvv() ?
                                <span className='greenTick'>
                                    <FontAwesomeIcon color='var(--green-color)' icon={faCheck} />
                                </span>
                                :
                                <>
                                </>
                        }
                    </label>
                    <input
                        ref={cvvRef}
                        type="text"
                        minLength={3}
                        maxLength={4}
                        name="cvv"
                        value={card['cvv']}
                        onInput={handleInputChange}
                        placeholder='123'
                        required
                    >

                    </input>
                </div>

            </div>
            <div className='row'>
                <div className='six columns'>
                    <label>PAUG amount {vendLimits}<span className='star'></span>
                        {
                            validateAmount() ?
                                <span className='greenTick'>
                                    <FontAwesomeIcon color='var(--green-color)' icon={faCheck} />
                                </span>
                                :
                                <>
                                </>
                        }
                    </label>
                    <input
                        ref={amountRef}
                        type="number"
                        step="0.01"
                        name="amount"
                        value={card['amount']}
                        onInput={handleInputChange}
                        placeholder='5.00'
                        required
                    >
                    </input>
                </div>
            </div>
        </>
    )
} 